import React, { useEffect, useState} from "react";
import { Button, TextField, InputLabel, Select, MenuItem, FormControl, Hidden, Grid, Box, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAccountsReceivable } from "../../lib/api/accounts_receivable"
import { useForm, Controller } from "react-hook-form";
import { Input, defaultValues, AccountsReceivableFormInfo } from '../../common/accounts_receivable';

export const SalesToShow = () =>{
  const { control, setValue, getValues} = useForm<Input>({defaultValues});
  const [ tpdId, setTpdId] = useState();
  const navigate = useNavigate();

  const handleGetAccount = async (pid: string) => {
    try{
      const res = await getAccountsReceivable(pid);
      if (res.status === 200 && res.data.record != null) {
        const record = res.data.record;
        setValue('kind', !record.type ? '' :record.type);
        setValue('companyName', !record.name ? '' :record.name);
        setValue('state', !record.state ? '' :record.state);
        setValue('city', !record.city ? '' :record.city);
        setValue('street', !record.street ? '' :record.street);
        setValue('other', !record.other ? '' : record.other);
        setValue('postalcode', !record.postalcode ? '' :record.postalcode);
        setValue('hpAddress', !record.hp ? '' :record.hp);
        setValue('phone', !record.telno ? '' :record.telno);
        setValue('fixDate', !record.closingdate ? '' : record.closingdate);
        setValue('paymentDate0', !record.paymentdate ? '' : record.paymentdate);
        setValue('manager', !record.personincharge ? '' :record.personincharge);
        setValue('houjinNo', !record.corporationnumber ? '' :record.corporationnumber);
        setTpdId(record.id);
      }
    }catch(e){
      console.log(e);
      navigate('/ar_list');
    }
  }

  const {id} = useParams();
  useEffect(() => {
    if(id){
      handleGetAccount(id);
    }
  }, [])

  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs:1, md: 2}, pb: 2}}>
      {AccountsReceivableFormInfo.map((group, index) =>(
        <Box key={`stm_${index}`}>
        <Box sx={{
          textAlign: 'left',
          mt:2,
          backgroundColor: '#EDF1F8'
        }}>
          <Box sx={{display: 'flex', ml: 4}}>
            <Box component='span' sx={{
              backgroundColor: '#51A7F2',
              width: '3px',
              height: '25px',
              display: 'inline-block',
              border: '1px solid #51A7F2',
              transform: 'rotate(45deg)',
              borderRadius: '5px',
              mb: '-6px',
              mr: '5px'
            }}/>
            <Box component='span' sx={{
              backgroundColor: '#DF7A99',
              width: '3px',
              height: '25px',
              display: 'inline-block',
              border: '1px solid #DF7A99',
              transform: 'rotate(45deg)',
              borderRadius: '5px',
              mb: '-6px'
            }}/>
            <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 2}}>{group.title}</Typography>
          </Box>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>{group.message}</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}>{group.warning}</Typography>
        </Box>
        <Box
          key={`stb_${index}`}
          sx={{pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
          { 
            group.forms.map((pform, idx) =>(
              pform.fields.length > 1 && pform.id == 'horizontal' ?
                <Grid container key={`pf_g_${idx}`}>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      {pform.label}
                    </InputLabel>
                  </Grid>
                  {pform.fields.map((f) =>(
                    <Controller
                      key={f.id}
                      control={control}
                      name={f.id}
                      render={({ field, fieldState }) => (
                        <Grid item md={4.5} sm={6} xs={6}>
                          <TextField
                            {...field}
                            fullWidth
                            disabled
                            sx={{mt: {xs: 0, md: 2}}}
                            margin="normal"
                            color="secondary"
                            placeholder={f.placeholder}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                          />
                        </Grid>
                      )}
                    />
                  ))}
                </Grid>
              : pform.fields.length > 1 && pform.id == 'vertical'?
                <Box sx={{mb: {xs: 1}}} key={`pf_b_${idx}`}>
                  {pform.fields.map((f, i) =>(
                      <Grid container key={`pf_b_${idx}_${i}`}>
                        <Grid item md={3} sm={12} xs={12}sx={{m: 'auto'}}>
                          <InputLabel required={i==0 ? pform.required : false} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                            {i == 0 ? pform.label : ''}
                          </InputLabel>
                        </Grid>
                        <Controller
                          key={f.id}
                          control={control}
                          name={f.id}
                          render={({ field, fieldState }) => (
                            <>
                            { pform.fields[0].id == 'paymentDate0' || pform.fields[0].id == 'fixDate' ?
                            <>
                              <Grid item md={4.5} sm={6} xs={6}>
                                <TextField
                                  {...field}
                                  fullWidth
                                  disabled
                                  sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                                  color="secondary"
                                  placeholder={f.placeholder}
                                  error={fieldState.invalid}
                                  helperText={fieldState.error?.message}
                                />
                              </Grid>
                              <Grid item md={4.5} sm={6} xs={6}></Grid>
                            </>
                            :
                            <Grid item md={9} sm={12} xs={12}>
                              <TextField
                                {...field}
                                fullWidth
                                disabled
                                sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                                color="secondary"
                                placeholder={f.placeholder}
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                              />
                            </Grid>
                            }
                            </>
                          )}
                        />
                      </Grid>
                    ))}
                </Box>
              : pform.fields.length > 1 && pform.id == 'verticalext' ?
                <Box sx={{mb: {xs: 1}}} key={`pf_bve`}>
                  <Grid container>
                    <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                      <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                        {pform.label}
                      </InputLabel>
                    </Grid>
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      render={({ field, fieldState }) => (
                        <Grid item md={4.5} sm={6} xs={6} sx={{display: 'flex'}}>
                          <Grid container>
                            <Grid item md={3} sm={3} xs={3}>
                              <Box sx={{textAlign: 'left', display: {md: 'flex', xs: 'flex'}, flexDirection: 'column', justifyContent: 'center', height: '100%', fontSize: {xs: '0.8rem', md: '0.9rem', sm: '0.8rem'}}}>締日</Box>
                            </Grid>
                            <Grid item md={9} sm={9} xs={9}>
                              <TextField
                                {...field}
                                fullWidth
                                disabled
                                color="secondary"
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    />
                    <Grid item md={4.5} sm={0} xs={0}>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                      <InputLabel required={false} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      </InputLabel>
                    </Grid>
                    <Grid item md={4.5} sm={6} xs={6}>
                      <Grid container>
                        <Grid item md={3} sm={3} xs={3} sx={{display: {md: 'flex', xs: 'flex'}}}>
                          <Box sx={{textAlign: 'left', mt: {xs: 0, sm: 0, md: 0}, flexDirection: 'column', display: 'flex', justifyContent: 'center', height: '100%', fontSize: {xs: '0.8rem', md: '0.9rem', sm: '0.8rem'}}}>支払日</Box>
                        </Grid>
                        <Controller
                          key={pform.fields[1].id}
                          control={control}
                          name={pform.fields[1].id}
                          render={({ field, fieldState }) => (
                            <Grid item md={9} sm={9} xs={9}>
                              <TextField
                                {...field}
                                fullWidth
                                disabled
                                sx={{mt: {xs: 0, md: 1, sm: 1}}}
                                color="secondary"
                                error={fieldState.invalid}
                                helperText={fieldState.error?.message}
                              />
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={4.5} sm={0} xs={0}>
                    </Grid>
                  </Grid>
                </Box>
              : pform.fields.length == 1 && (pform.fields[0].type == 'string'?
                <Controller
                  key={pform.fields[0].id}
                  control={control}
                  name={pform.fields[0].id}
                  disabled
                  render={({ field }) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          {pform.label}
                        </InputLabel>
                      </Grid>
                      { pform.fields[0].id == 'phone' || pform.fields[0].id == 'manager' ?
                      <>
                      <Grid item md={4.5} sm={6} xs={6}>
                        <TextField
                          {...field}
                          fullWidth
                          sx={{mt: {xs: 1, md: 2}}}
                          margin="normal"
                          color="secondary"
                        />
                      </Grid>
                      <Grid item md={4.5} sm={6} xs={6}></Grid>
                      </>
                      :
                      <Grid item md={9} sm={12} xs={12}>
                        <TextField
                          {...field}
                          fullWidth
                          sx={{mt: {xs: 1, md: 2}}}
                          margin="normal"
                          color="secondary"
                        />
                      </Grid>
                      }
                    </Grid>
                  )}
                />
              : pform.fields.length == 1 && (pform.fields[0].type == 'select') ?
                <Controller
                  key={pform.fields[0].id}
                  control={control}
                  name={pform.fields[0].id}
                  disabled
                  render={({ field }) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          {pform.label}
                        </InputLabel>
                      </Grid>
                      <Grid item md={4.5} sm={6} xs={6}>
                        <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}}>
                          <Select
                            labelId='kind_select'
                            {...field}
                            color="secondary"
                          >
                            <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                            {pform.fields[0].options.map((opt) =>(
                              <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4.5} sm={6} xs={6}></Grid>
                    </Grid>
                  )}
                />
              : <Box key={`pf_n_${idx}`}></Box>
            )
          ))}
        </Box>
        </Box>
      ))}
      <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 1, md: '15vh'}, mt: 2}}>
        <Button
          variant="contained"
          color="warning"
          component={Link} to={'/ar_list'}
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 1, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
        >トップページに戻る</Button>
      </Box>
    </Box>
  );
};
import React, { useRef } from 'react';
import { Button, TextField, InputLabel, Select, MenuItem, FormControl, Hidden, FormGroup, Autocomplete, Box, FormHelperText, Grid, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Input, defaultValues, profileFormInfo, validationRules, Corporate } from '../../../common/profile';
import { LocalizationProvider, DatePicker, DatePickerToolbar } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import Loading from '../../../components/Loading';
import { DragDropFileUpload } from '../../../components/DragDropFileUpload';
import { weekdayTexts, CustomDatePickerToolbar, toFullWidth, toFullWidthKana } from '../../../common/common';
import { ErrorModal } from '../../../components/ErrorModal';
import { Element, scroller} from 'react-scroll';
import { getBanks, getBranches, getCorporates, getProfile, sendErrorMail } from '../../../lib/api/admin';
import { getAddress } from '../../../lib/api/postalCode';
import { otherCompaniesPast } from '../../../common/profile';
import { WarningModal } from '../../../components/WarningModal';

const TextFieldWText = styled(TextField)(({theme}) =>({
  width: 'calc(100% - 90px)',
  "& fieldset": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  }
}));

const TextFieldWButton = styled(Button)(({theme}) =>({
  height: '56px',
  width: '90px',
  marginTop: '16.5px',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
}));

interface Banks{
  code: string;
  name: string;
  businessType: string;
  businessTypeCode: string;
  fullWidthKana: string;
  halfWidthKana: string;
  hiragana: string;
}

interface Branches{
  code: string;
  name: string;
  fullWidthKana: string;
  halfWidthKana: string;
  hiragana: string;
}

export const AdminProfileEdit = () =>{
  const { control, handleSubmit, setValue, getValues, setError, clearErrors} = useForm<Input>({defaultValues});
  const [kindState, setKind] = useState('');
  const [banks, setBanks] = useState<Banks[]>([]);
  const bankRef = useRef<HTMLInputElement>(null);
  const branchRef = useRef<HTMLInputElement>(null);
  const corpRef = useRef<HTMLInputElement>(null);
  const [branches, setBranches] = useState<Branches[]>([]);
  const [corporates, setCorporates] = useState<Corporate[]>([]);
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [emergencyDisable, setEmergencyDisable] = useState({emergencycontactName: false, emergencycontactFurigana: false, emergencycontactRelationship: false, emergencycontactPhoneno: false});
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [isApiButton, setIsApiButton] = useState(false);
  const [firstNameDisabled, setFirstNameDisabled] = useState(false);
  const [birthdateDisabled, setBirthdateDisabled] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [sameAddress, setSameAddress] = useState(false);
  const [disableComp, setDisableComp] = useState(true);
  const [disableCompOther, setDisableCompOther] = useState(true);
  const [disableBankOther, setDisableBankOther] = useState(true);
  const [birthdateError, setBirthdateError] = useState('');
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [orgFirstName, setOrgFirstName] = useState<any>();
  const [orgBirthday, setOrgBirthday] = useState<any>();
  const [orgEmail, setOrgEmail] = useState<any>();
  const [orgMobile, setOrgMobile] = useState<any>();
  const [tmpEvent, setTmpEvent] = useState<any>();

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleWarningModal = () =>{
    if(warningModalOpen) setIsLoading(false);
    setWarningModalOpen(!warningModalOpen);
  }

  const onClickWarningCancel = () =>{
    navigate("/admin/apply/factoring_new");
  }

  const onClickWarningOk = async() =>{
    try{
      setIsLoading(false);
      handleOpenErrorModal();
      navigate("/admin/profile/edit/authentication/" + id + "?message=true", {state: tmpEvent});
    }catch(e){
      console.log(e);
    }
  }

  const handleGetLead = async () => {
    const res = await getProfile();

    if (res.status === 200) {
      const record = res.data.record;
      const lastStatus = record.lastOppStatus;
      if(lastStatus && lastStatus != '見込み' && lastStatus != 'NG' && lastStatus != 'キャンセル' && lastStatus != '実行済み'){
        setIsCancel(true);
      }
      if(lastStatus == '作成依頼' || lastStatus == 'CS送信済み' || lastStatus == '作成後本人確認済み' || lastStatus == '顧客CS承認済み' || lastStatus == '作成済み/本人確認未了' || lastStatus == 'CS承認依頼' || lastStatus == '契約締結済み' || lastStatus == '契約内容変更'){
        navigate("/admin/profile/" + record.id);
        return;
      }
      if(record.identification == '確認中'){
        navigate("/admin/profile/" + record.id);
        return;
      }
      setBanks([{
        name: record.bankname ? record.bankname : '',
        code: record.bankcode ? record.bankcode : '',
        hiragana: record.bankcodeHiragana ? record.bankcodeHiragana : '',
        businessTypeCode: record.bankcodeBusinesstypecode ? record.bankcodeBusinesstypecode : '',
        businessType: record.bankcodeBusinesstype ? record.bankcodeBusinesstype : '',
        halfWidthKana: record.banknameHalfwidthkana ? record.banknameHalfwidthkana : '',
        fullWidthKana: record.banknameFullwidthkana ? record.banknameFullwidthkana: '' 
      }]);
      setBranches([{
        name: record.branchname ? record.branchname : '',
        code: record.branchcode ? record.branchcode : '',
        hiragana: record.branchnameHiragana ? record.branchnameHiragana : '',
        fullWidthKana: record.branchnameFullwidthkana ? record.branchnameFullwidthkana : '',
        halfWidthKana: record.branchnameHalfwidthkana ? record.branchnameHalfwidthkana : ''
      }])
      setCorporates([{
        name: record.company || '',
        corporateNumber: record.corporatenumberAccount || '',
        furigana: record.companynameFurigana || '',
        postCode: record.postalcode || '',
        prefectureName: record.state || '',
        cityName: record.city || '',
        streetNumber: record.street || ''
      }]);
      setValue('firstname', !record.firstname || record.firstname == '未設定' ? '' :record.firstname);
      setOrgFirstName(!record.firstname || record.firstname == '未設定' ? '' :record.firstname);
      setValue('lastname', !record.lastname || record.lastname == '未設定' ? '' :record.lastname);
      setValue('state', !record.state ? '' :record.state);
      setValue('email', !record.email ? '' :record.email);
      setOrgEmail(!record.email ? '' :record.email);
      setValue('company', !record.company ? '' :record.company);
      setValue('companynameFurigana', !record.companynameFurigana ? '' : record.companynameFurigana);
      setValue('mobilephone', !record.phone ? '' :record.phone);
      setValue('city', !record.city ? '' :record.city);
      setValue('street', !record.street ? '' :record.street);
      setValue('postalcode', !record.postalcode ? '' :record.postalcode);
      setValue('kind', !record.kind ? '' :record.kind);
      setValue('industry', !record.kind ? '' :record.kind);
      setValue('businessPostalcode', !record.businessPostalcode ? '' :record.businessPostalcode);
      setValue('businessState', !record.businessState ? '' :record.businessState);
      setValue('businessCity', !record.businessCity ? '' :record.businessCity);
      setValue('businessStreet', !record.businessStreet ? '' :record.businessStreet);
      setValue('businessOther', !record.businessOther ? '' :record.businessOther);
      setValue('corporatenumberAccount', !record.corporatenumberAccount ? '' :record.corporatenumberAccount);
      setValue('position', !record.position ? '' :record.position);
      setValue('firstnamekana', !record.firstnamekana ? '' :record.firstnamekana);
      setValue('lastnamekana', !record.lastnamekana ? '' :record.lastnamekana);
      setValue('raPostalcode', !record.raPostalcode ? '' :record.raPostalcode);
      setValue('raState', !record.raState ? '' :record.raState);
      setValue('raCity', !record.raCity ? '' :record.raCity);
      setValue('raStreet', !record.raStreet ? '' :record.raStreet);
      setValue('raOther', !record.raOther ? '' :record.raOther);
      setValue('mobilephone', !record.mobilephone ? '' :record.mobilephone);
      setOrgMobile(!record.mobilephone ? '' :record.mobilephone);
      setValue('seibetsu', !record.seibetsu ? '' :record.seibetsu);
      setValue('birthdate', !record.birthdate ? null : dayjs(record.birthdate));
      setOrgBirthday(!record.birthdate ? null : dayjs(record.birthdate));
      setValue('accounttype', !record.accounttype ? '' :record.accounttype);
      setValue('accounttypeOther', !record.accounttypeOther ? '' :record.accounttypeOther);
      setValue('accountnumber', !record.accountnumber ? '' :record.accountnumber);
      setValue('accountholder', !record.accountholder ? '' :record.accountholder);
      setValue('accountnameKana', !record.accountnameKana ? '' :record.accountnameKana);
      setValue('identification', !record.identification ? '' :record.identification);
      setValue('industry', !record.industry ? '' : record.industry);
      setValue('tashariyoureki', !record.tashariyoureki ? '' : record.tashariyoureki);
      if(!record.accounttype || record.accounttype != 'その他'){
        setDisableBankOther(true);
      }else{
        setDisableBankOther(false);
      }
      if(!record.tashariyoureki || record.tashariyoureki == '無し'){
        setDisableComp(true);
      }else{
        setDisableComp(false);
      }
      if(record.othercompaniesusedinthepast){
        if(otherCompaniesPast.indexOf(record.othercompaniesusedinthepast) >= 0){
          setValue('othercompaniesusedinthepast', record.othercompaniesusedinthepast);
          setValue('othercompaniesusedinthepastOther', '');
          setDisableCompOther(true);
        }else{
          setValue('othercompaniesusedinthepast', 'その他');
          setValue('othercompaniesusedinthepastOther', record.othercompaniesusedinthepast);
          setDisableCompOther(false);
        }
      }else{
        setValue('othercompaniesusedinthepast', '');
        setDisableCompOther(true);
      }
      setEmergencyDisable({
        emergencycontactName: !!record.emergencycontactName,
        emergencycontactFurigana: !!record.emergencycontactFurigana,
        emergencycontactRelationship: !!record.emergencycontactRelationship,
        emergencycontactPhoneno: !!record.emergencycontactPhoneno
      });
      if(record.kind == '個人事業主'){
        if(record.firstname) setFirstNameDisabled(true);
        if(record.birthdate) setBirthdateDisabled(true);
      }
      setIsLoad(true);
    }
  }

  const {id} = useParams();
  useEffect(() => {
    handleGetLead();
  }, [])

  const onError = (evt) =>{
    const fields = [
      'kind', 'company', 'companynameFurigana', 'postalcode', 'state', 'city', 'street',
      'businessPostalcode', 'businessState', 'businessCity', 'businessStreet', 'businessOther',
      'corporatenumberAccount', 'industry', 'tashariyoureki', 'othercompaniesusedinthepast', 'position', 'lastname', 'firstname',
      'lastnamekana', 'firstnamekana', 'raPostalcode', 'raState', 'raCity', 'raStreet', 'raOther',
      'email', 'mobilephone', 'seibetsu', 'birthdate', 'identification', 'banknameSearch', 'branchname',
      'accounttype', 'accounttypeOther', 'accountnumber', 'accountholder', 'accountnameKana', 'emergencycontactName', 'emergencycontactFurigana',
      'emergencycontactRelationship', 'emergencycontactPhoneno'
    ]
    const keys = Object.keys(evt);
    let targetKey = '';
    for(let i = 0; i < fields.length; i++){
      if(keys.find(k => k == fields[i])){
        targetKey = fields[i];
        break;
      }
    }
    if(birthdateError.length > 0 && ['identification', 'banknameSearch', 'branchname', 'accounttype', 'accounttypeOther', 'accountnumber', 'accountholder', 'accountnameKana'].indexOf(targetKey) >= 0){
      targetKey = 'birthdate';
    }
    const offset = window.innerWidth <= 768 ? -162 : -75;
    scroller.scrollTo(targetKey, {
      smooth: true,
      offset: offset
    })
  };

  useEffect(() => {
    if(!isLoad) return;
    if(banks.length != 1) return;
    const values = getValues();
    setKind(values.kind);
    const bank = banks[0];
    setValue('bankname', bank.name);
    setValue('banknameSearch', bank.name);
    setValue('bankcode', bank.code);
    setValue('bankcodeBusinesstypecode', bank.businessTypeCode);
    setValue('bankcodeBusinesstype', bank.businessType);
    setValue('bankcodeHiragana', bank.hiragana);
    setValue('banknameFullwidthkana', bank.fullWidthKana);
    setValue('banknameHalfwidthkana', bank.halfWidthKana);
    if(branches.length != 1) return;
    const branch = branches[0];
    setValue('branchname', branch.name);
    setSelectedBranch(branch.name);
    setValue('branchcode', branch.code);
    setValue('branchnameHiragana', branch.hiragana);
    setValue('branchnameFullwidthkana', branch.fullWidthKana);
    setValue('branchnameHalfwidthkana', branch.halfWidthKana);
  }, [isLoad])

  const onSubmit: SubmitHandler<Input> = async (evt) =>{
    if(isLoading) return;
    if(birthdateError.length > 0){
      const offset = window.innerWidth <= 768 ? -162 : -75;
      scroller.scrollTo(`birthdate`, {
        smooth: true,
        offset: offset
      })
      return;
    }
    setIsLoading(true);
    if(!id) return;
    // 同じ住所を使う場合書き換える
    if(sameAddress){
      evt['raPostalcode'] = evt.businessPostalcode;
      evt['raState'] = evt.businessState;
      evt['raCity'] = evt.businessCity;
      evt['raStreet'] = evt.businessStreet;
      evt['raOther'] = evt.businessOther;
    }
    // 住所情報を入れる
    if(evt.kind == '個人事業主'){
      evt.postalcode = evt.raPostalcode;
      evt.state = evt.raState;
      evt.city = evt.raCity;
      evt.street = evt.raStreet;
    }
    // 口座番号を0パディング
    if(evt.accountnumber){
      evt.accountnumber = ('0000000' + evt.accountnumber).slice(-7)
    }
    if(evt.othercompaniesusedinthepast == 'その他'){
      evt.othercompaniesusedinthepast = evt.othercompaniesusedinthepastOther;
    }
    try{
      if(orgFirstName != evt.firstname || orgEmail != evt.email || !orgBirthday.isSame(evt.birthdate)){
        setTmpEvent(evt);
        handleWarningModal();
      }else{
        if(evt.mobilephone == orgMobile){
          navigate("/admin/profile/edit/completed/" + id);
        }else{
          navigate("/admin/profile/edit/authentication/" + id, {state: evt});
        }
      }
    }catch(e){
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  const handleChangeKind = (value) =>{
    if(!value) return;
    setValue('kind', value);
    setKind(value);
  }

  const handleChangeTashariyoureki = (value) =>{
    setValue('tashariyoureki', value);
    const vals = getValues();
    if(value == '有り'){
      setDisableComp(false);
      if(vals.othercompaniesusedinthepast == 'その他'){
        setDisableCompOther(false);
      }else{
        setDisableCompOther(true);
      }
    }else{
      setDisableComp(true);
      setDisableCompOther(true);
      setValue('othercompaniesusedinthepast', '');
      setValue('othercompaniesusedinthepastOther', '');
    }
  }

  const handleChangeCompany = (value) =>{
    setValue('othercompaniesusedinthepast', value);
    if(value == 'その他'){
      setDisableCompOther(false);
    }else{
      setDisableCompOther(true);
      setValue('othercompaniesusedinthepastOther', '');
    }
  }

  const handleChangeAccountType = (value) =>{
    setValue('accounttype', value);
    if(value == 'その他(記述式)'){
      setDisableBankOther(false);
    }else{
      setDisableBankOther(true);
      setValue('accounttypeOther', '');
    }
  }

  const handleAddressSearch = async (fid, value) =>{
    if(isApiButton) return;
    setIsApiButton(true);
    const res = await getAddress(value);
    const stateStr = fid == 'postalcode' ? 'state'
      : fid == 'businessPostalcode' ? 'businessState'
      : 'raState'
      const cityStr = fid == 'postalcode' ? 'city'
      : fid == 'businessPostalcode' ? 'businessCity'
      : 'raCity'
      const streetStr = fid == 'postalcode' ? 'street'
      : fid == 'businessPostalcode' ? 'businessStreet'
      : 'raStreet'
    if(res.status == 200 && res.data.results){
      // 1件取得できた時だけ値を更新する
      const address = res.data.results[0];
      setValue(stateStr, address.address1);
      setValue(cityStr, address.address2);
      setValue(streetStr, address.address3);
    }else{
      if(res.status == 500){
        await sendErrorMail(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${value}`, JSON.stringify(res.data), 'ZIP Cloud API');
      }
      setErrorMessage(<Typography>郵便番号を再入力してください</Typography>);
      handleOpenErrorModal();
    }
    setIsApiButton(false);
  }

  const onClickCancel = () =>{
    if(!id) return;
    navigate("/admin/profile/" + id);
  }

  const searchBank = async() =>{
    if(isApiButton) return;
    setIsApiButton(true);
    try{
      const values = getValues();
      const ret = await getBanks({bankname: values.banknameSearch});
      if(ret.status == 200){
        setBanks(ret.data.records.banks);
        if(ret.data.records.banks && ret.data.records.banks.length == 1){
          setValue('bankname', ret.data.records.banks[0].name);
          setValue('banknameSearch', ret.data.records.banks[0].name);
          setValue('bankcode', ret.data.records.banks[0].code);
          setValue('bankcodeBusinesstypecode', ret.data.records.banks[0].businessTypeCode);
          setValue('bankcodeBusinesstype', ret.data.records.banks[0].businessType);
          setValue('bankcodeHiragana', ret.data.records.banks[0].hiragana);
          setValue('banknameFullwidthkana', ret.data.records.banks[0].fullWidthKana);
          setValue('banknameHalfwidthkana', ret.data.records.banks[0].halfWidthKana);
          const ret2 = await getBranches({bankCode: ret.data.records.banks[0].code, branchName: ''});
          if(ret2.status == 200){
            if(ret2.data.records.branches) setBranches(ret2.data.records.branches);
          }
        }
        if(bankRef.current) bankRef.current.focus();
        setIsApiButton(false);
      }
    }catch(e){
      console.log(e);
      setErrorMessage(<Typography>銀行情報の取得に失敗しました。再度検索ボタンを押してください。</Typography>);
      handleOpenErrorModal();
      setIsApiButton(false);
    }
  }

  const searchBranch = async() =>{
    const values = getValues();
    if(!values.bankcode) return ;
    try{
      const ret = await getBranches({bankCode: values.bankcode, branchName: values.branchname});
      if(ret.status == 200){
        if(ret.data.records.branches) setBranches(ret.data.records.branches);
      }
    }catch(e){
      console.log(e);
    }
  }

  const searchCompany = async() =>{
    const values = getValues();
    try{
      const ret = await getCorporates({name: values.company});
      const count = parseInt(ret.data.records.corporations.count);
      if(count > 1){
        setCorporates(ret.data.records.corporations.corporation);
      }else if(count == 1){
        setCorporates([ret.data.records.corporations.corporation]);
      }else{
        setCorporates([{
          name: '',
          corporateNumber: '',
          furigana: '',
          postCode: '',
          prefectureName: '',
          cityName: '',
          streetNumber: ''
        }])
        setErrorMessage(<Typography>検索候補がありません、入力内容を再度ご確認ください</Typography>);
        handleOpenErrorModal();
      }
    }catch(e){
      setErrorMessage(<Typography>{e.response.data.message}</Typography>);
      handleOpenErrorModal();
    }
  }

  const handleChangeAddress = (evt) =>{
    setSameAddress(evt.target.checked);
  }

  return (
    <Box sx={{pr: {xs: 1, md: 2}, pl: {xs: 1, md: 2}, pb: 2}}>
      <form id="user-edit-form" onSubmit={handleSubmit(onSubmit, onError)}>
      {profileFormInfo.map((group, index) =>(
        <Box key={`pf_${index}`}>
        <Box sx={{
          textAlign: 'left',
          mt:2,
          backgroundColor: '#EDF1F8'
        }}>
          <Box sx={{display: 'flex', ml: 4}}>
            <Box component='span' sx={{
              backgroundColor: '#51A7F2',
              width: '3px',
              height: '25px',
              display: 'inline-block',
              border: '1px solid #51A7F2',
              transform: 'rotate(45deg)',
              borderRadius: '5px',
              mb: '-6px',
              mr: '5px'
            }}/>
            <Box component='span' sx={{
              backgroundColor: '#DF7A99',
              width: '3px',
              height: '25px',
              display: 'inline-block',
              border: '1px solid #DF7A99',
              transform: 'rotate(45deg)',
              borderRadius: '5px',
              mb: '-6px'
            }}/>
            <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 2}}>{group.title}</Typography>
          </Box>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>{group.message}</Typography>
          <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}>{group.warning}</Typography>
          {group.warnings && group.warnings.length > 0 ?
          group.warnings.map((w, ii) =>(
            <Typography key={`pf_l_${ii}`} sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}>{w}</Typography>
          ))
          :<></>}
        </Box>
        <Box
          key={`pfb_${index}`}
          sx={{pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
        >
        { 
          group.forms.map((pform, idx) =>(
            pform.fields.length > 1 && pform.id == 'horizontal' ?
            <Grid container key={`pf_g_${idx}`}>
            <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
              <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                {pform.label}
              </InputLabel>
              <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                {pform.fields[0]?.message}
              </InputLabel>
            </Grid>
              {pform.fields.map((f) =>(
                <Controller
                key={f.id}
                control={control}
                name={f.id}
                rules={validationRules[f.id]}
                render={({ field, fieldState }) => (
                  <Grid item md={4.5} sm={6} xs={6}>
                    <Element name={f.id}></Element>
                    <TextField
                      {...field}
                      fullWidth
                      sx={{mt: {xs: 1, md: 2}}}
                      margin="normal"
                      color="secondary"
                      disabled={f.id == 'firstname' ? firstNameDisabled : false}
                      placeholder={f.placeholder}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  </Grid>
                )}
              />
            ))}
            </Grid>
          : pform.fields.length > 1 && pform.id == 'vertical' &&( (pform.label == '事業所住所' && kindState == '個人事業主') || (pform.label == '会社住所' && kindState == '法人') || (pform.label == '代表者住所' && kindState == '法人') || (pform.label == '代表者住所' && kindState == '個人事業主' && !sameAddress) )?
          <Box sx={{mb: {xs: 1}}} key={`pf_b_${idx}`}>
            {pform.fields.map((f, i) =>(
              <Grid container key={`pf_b_${idx}_${i}`}>
                <Grid item md={3} sm={12} xs={12}sx={{m: 'auto'}}>
                  <InputLabel required={i==0 ? pform.required : false} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    {i == 0 ? pform.label : ''}
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    {f?.message}
                  </InputLabel>
                </Grid>
                <Controller
                  key={f.id}
                  control={control}
                  name={f.id}
                  rules={validationRules[f.id]}
                  render={({ field, fieldState }) => (
                    <Grid item md={9} sm={12} xs={12}>
                      {f.id == 'postalcode' || f.id == 'businessPostalcode' || f.id == 'raPostalcode' ?
                      <FormGroup row>
                        <Element name={f.id}></Element>
                        <TextFieldWText
                          {...field}
                          fullWidth
                          sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                          color="secondary"
                          placeholder={f.placeholder}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          disabled={
                            (pform.label == '代表者住所' && kindState == '個人事業主') && sameAddress?
                            true : false
                          }
                        />
                        <TextFieldWButton
                          disabled={
                            (pform.label == '代表者住所' && kindState == '個人事業主') && sameAddress?
                            true : isApiButton
                          } 
                          sx={{mt: {xs: 1, md: 1, sm: 1}}} 
                          variant="contained" 
                          onClick={(e) =>{handleAddressSearch(f.id, field.value)}}
                        >住所検索</TextFieldWButton>
                      </FormGroup>
                      :
                      <>
                      <Element name={f.id}></Element>
                      <TextField
                        {...field}
                        onBlur={(e) => {
                          let value = e.target.value;
                          if(value){
                            value = toFullWidth(value);
                            value = toFullWidthKana(value);
                            setValue(f.id, value);
                          }
                        }}
                        fullWidth
                        sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                        color="secondary"
                        placeholder={f.placeholder}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        disabled={
                          (pform.label == '代表者住所' && kindState == '個人事業主') && sameAddress?
                          true : false
                        }
                      />
                      </>
                      }
                    </Grid>
                  )}
                />
              </Grid>
            ))}
            {(pform.label == '事業所住所' && kindState == '個人事業主') ? 
              <Grid container>
                <Grid item md={3} sm={12} xs={12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox onChange={handleChangeAddress}/>} label="代表者住所も同じ住所を使う" />
                  </FormGroup>
                </Grid>
              </Grid>
              :
              <></>
            }
          </Box>
        : pform.fields.length == 1 && (pform.fields[0].type == 'string') && (kindState == '法人' || (kindState == '個人事業主' && pform.fields[0].id != 'corporatenumberAccount')) ?
          <Controller
            key={pform.fields[0].id}
            control={control}
            name={pform.fields[0].id}
            rules={validationRules[pform.fields[0].id]}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    {kindState == '個人事業主' && (pform.fields[0].id == 'company' || pform.fields[0].id == 'companynameFurigana') ? pform.label.replace('会社名', '屋号') : pform.label}
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    {kindState == '個人事業主' && pform.fields[0].id == 'companynameFurigana' ? '※屋号がない場合は代表者氏名（フリガナ）を全角入力' : pform.fields[0]?.message}
                  </InputLabel>
                </Grid>
                { pform.fields[0].id == 'accountnumber' || pform.fields[0].id == 'accounttypeOther' || pform.fields[0].id == 'mobilephone' || pform.fields[0].id == 'position' ?
                <>
                  <Grid item md={4.5} sm={6} xs={6}>
                    <Element name={pform.fields[0].id}></Element>
                    <TextField
                      {...field}
                      fullWidth
                      sx={{mt: {xs: 1, md: 2}}}
                      margin="normal"
                      color="secondary"
                      placeholder={pform.fields[0].placeholder}
                      disabled={
                        pform.fields[0].disable ?
                          pform.fields[0].disable :
                          pform.fields[0].id == 'accounttypeOther' ?
                          disableBankOther :
                          false
                      }
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  </Grid>
                  <Grid item md={4.5} sm={6} xs={6}></Grid>
                </>
                : pform.fields[0].id == 'identification' ?
                <>
                  <Grid item md={4.5} sm={6} xs={6}>
                    <Element name={pform.fields[0].id}></Element>
                    <TextField
                      {...field}
                      fullWidth
                      disabled
                      sx={{mt: {xs: 1, md: 2}, 'fieldset': {border: 'none'}}}
                      margin="normal"
                      color="secondary"
                    />
                  </Grid>
                  <Grid item md={4.5} sm={6} xs={6}></Grid>
                </>
                :
                <Grid item md={9} sm={12} xs={12}>
                  <Element name={pform.fields[0].id}></Element>
                  <TextField
                    {...field}
                    fullWidth
                    sx={{mt: {xs: 1, md: 2}}}
                    margin="normal"
                    color="secondary"
                    placeholder={pform.fields[0].placeholder}
                    disabled={
                      pform.fields[0].disable ?
                        pform.fields[0].disable :
                        pform.fields[0].id == 'othercompaniesusedinthepastOther' ?
                        disableCompOther :
                        false
                    }
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                </Grid>
                }
              </Grid>
            )}
          />
        : pform.fields.length == 1 && (pform.fields[0].type == 'select') ?
          <Controller
            key={pform.fields[0].id}
            control={control}
            name={pform.fields[0].id}
            rules={validationRules[pform.fields[0].id]}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    {pform.label}
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6}>
                  <Element name={pform.fields[0].id}></Element>
                  <FormControl fullWidth margin='normal' error={fieldState.invalid} sx={{mt: {xs: 1, md: 2}}}>
                    <Select
                      labelId='kind_select'
                      {...field}
                      color="secondary"
                      disabled={
                        pform.fields[0].disable ?
                          pform.fields[0].disable :
                          pform.fields[0].id == 'othercompaniesusedinthepast' ?
                          disableComp :
                          false
                      }
                      onChange={(evt) =>{pform.fields[0].id == 'kind' ? 
                        handleChangeKind(evt.target.value) :
                        pform.fields[0].id == 'tashariyoureki' ?
                        handleChangeTashariyoureki(evt.target.value) :
                        pform.fields[0].id == 'othercompaniesusedinthepast' ?
                        handleChangeCompany(evt.target.value) :
                        pform.fields[0].id == 'accounttype' ?
                        handleChangeAccountType(evt.target.value) :
                        setValue(pform.fields[0].id, evt.target.value)}}
                    >
                      <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                      {pform.fields[0].options.map((opt) =>(
                        <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={6} xs={6}></Grid>
              </Grid>
            )}
          />
        : pform.fields.length == 1 && (pform.fields[0].type == 'calendar') ?
          <Controller
            key={pform.fields[0].id}
            control={control}
            rules={validationRules[pform.fields[0].id]}
            name={pform.fields[0].id}
            render={({ field, fieldState }) => (
              <Grid container>
                <Grid item md={3} sm={12} xs = {12} sx={{m: 'auto'}}>
                  <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    {pform.label}
                  </InputLabel>
                </Grid>
                <Grid item md={4.5} sm={12} xs={12}>
                  <Element name={pform.fields[0].id}></Element>
                  <FormControl margin='normal' fullWidth  sx={{mt: {xs: 1, md: 2}}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthAndYear: "YYYY年 MM月", monthShort: "MM月" }}>
                      <DatePicker
                        {...field}
                        format={'YYYY/MM/DD'}
                        referenceDate={dayjs(new Date()).subtract(30, 'y')}
                        disabled={pform.fields[0].id == 'birthdate' ? 
                          birthdateDisabled : 
                          pform.fields[0].disable ?
                          pform.fields[0].disable :
                          false
                        }
                        slotProps={{textField: {helperText: fieldState?.error?.message || birthdateError, error: Boolean(fieldState?.error) || !!birthdateError}}}
                        onError={(evt) => {
                          let message = '';
                          switch(evt){
                            case 'invalidDate': {
                              message = '日付の形式が不正です。';
                              break;
                            }
                            case 'minDate': {
                              message = '1990年01月01日より前の日付は選択できません。';
                              break;
                            }
                            default: {
                              message = '';
                              break;
                            } 
                          }
                          setBirthdateError(message);
                        }}
                        dayOfWeekFormatter={(weekday) => `${weekdayTexts[weekday]}`}
                        slots={{
                          toolbar: CustomDatePickerToolbar
                        }}
                        minDate={dayjs(new Date(1900, 0, 1))}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item md={4.5} sm={0} xs={0}></Grid>
              </Grid>
            )}
          />
        : pform.fields.length == 1 && (pform.fields[0].type == 'searchText') ?
          <Controller
            key={pform.fields[0].id}
            control={control}
            rules={validationRules[pform.fields[0].id]}
            name={pform.fields[0].id}
            render={({ field, fieldState }) => (
              pform.fields[0].id == 'banknameSearch' ?
                <Autocomplete
                  freeSolo
                  disabled={pform.fields[0].disable ? pform.fields[0].disable : false}
                  options={banks.map((option) => option.name)}
                  value={banks.length == 1 ? banks[0].name : ''}
                  onChange={(_event, value) => {
                    if(value && banks.length > 0){
                      setValue('bankname', value);
                      setValue('banknameSearch', value);
                      const bank = banks.find((bank) => bank.name == value);
                      setValue('bankcode', bank ? bank.code : '');
                      setValue('bankcodeBusinesstypecode', bank ? bank.businessTypeCode : '');
                      setValue('bankcodeBusinesstype', bank ? bank.businessType : '');
                      setValue('bankcodeHiragana', bank ? bank.hiragana : '');
                      setValue('banknameFullwidthkana', bank ? bank.fullWidthKana : '');
                      setValue('banknameHalfwidthkana', bank ? bank.halfWidthKana : '');
                      searchBranch();
                    }
                  }}
                  onInputChange={(_event, value, reason) =>{
                    if(reason == 'clear'){
                      setBranches([{name: '',code: '',hiragana: '',fullWidthKana: '',halfWidthKana: ''}]);
                      setValue('bankname', '');
                      setValue('banknameSearch', '');
                      setValue('bankcode', '');
                      setValue('bankcodeBusinesstype', '');
                      setValue('bankcodeBusinesstypecode', '');
                      setValue('bankcodeHiragana', '');
                      setValue('banknameFullwidthkana', '');
                      setValue('banknameHalfwidthkana', '');
                      setValue('branchname', '');
                      setSelectedBranch('');
                      setValue('branchcode', '');
                      setValue('branchnameHiragana', '');
                      setValue('branchnameFullwidthkana', '');
                      setValue('branchnameHalfwidthkana', '');
                    }
                    if(reason == 'reset' && _event && _event.type == 'click'){
                      setValue('bankname', value);
                      setValue('banknameSearch', value);
                      const bank = banks.find((bank) => bank.name == value);
                      setValue('bankcode', bank ? bank.code : '');
                      setValue('bankcodeBusinesstypecode', bank ? bank.businessTypeCode : '');
                      setValue('bankcodeBusinesstype', bank ? bank.businessType : '');
                      setValue('bankcodeHiragana', bank ? bank.hiragana : '');
                      setValue('banknameFullwidthkana', bank ? bank.fullWidthKana : '');
                      setValue('banknameHalfwidthkana', bank ? bank.halfWidthKana : '');
                      searchBranch();
                    }
                    if(reason == 'input'){
                      setBranches([{name: '',code: '',hiragana: '',fullWidthKana: '',halfWidthKana: ''}])
                      setValue('branchname', '');
                      setSelectedBranch('');
                      setValue('branchcode', '');
                      setValue('branchnameHiragana', '');
                      setValue('branchnameFullwidthkana', '');
                      setValue('branchnameHalfwidthkana', '');
                    }
                  }}
                  renderInput={(params) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          {pform.label}
                        </InputLabel>
                      </Grid>
                      <Grid item md={9} sm={12} xs={12}>
                        <Element name={pform.fields[0].id}></Element>
                        <FormGroup row >
                          <TextFieldWText
                            {...field}
                            {...params}
                            ref={bankRef}
                            sx={{mt: {xs: 1, md: 2}}}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                            margin="normal"
                          />
                          <TextFieldWButton disabled={isApiButton} sx={{mt: {xs: 1, md: 2}}} variant="contained" onClick={searchBank}>検索</TextFieldWButton>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}
                />
              : pform.fields[0].id == 'company' && kindState == '法人' ? 
                <Autocomplete
                  freeSolo
                  disabled={pform.fields[0].disable ? pform.fields[0].disable : false}
                  options={corporates}
                  getOptionLabel={(option: any) => option.name || ''}
                  renderOption={(props, option) => (
                    <Box key={option?.corporateNumber} >
                      <Typography {...props}>
                        {option?.name} ({option?.prefectureName}{option?.cityName}{option?.streetNumber})
                      </Typography>
                    </Box>
                  )}
                  value={corporates.length == 1 ? corporates[0] : ''}
                  onChange={(_event, value: any | null) => {
                    if(value){
                      setValue('company', value?.name);
                      setValue('companynameFurigana', value?.furigana);
                      setValue('postalcode', value?.postCode);
                      setValue('state', value.prefectureName);
                      setValue('city', value?.cityName);
                      setValue('street', value?.streetNumber);
                      setValue('corporatenumberAccount', value?.corporateNumber);
                    }
                  }}
                  onInputChange={(_event, value, reason) =>{
                    if(reason == 'clear' || reason == 'input'){
                      setValue('company', '');
                      setValue('companynameFurigana', '');
                      setValue('postalcode', '');
                      setValue('state', '');
                      setValue('city', '');
                      setValue('street', '');
                      setValue('corporatenumberAccount', '');
                    }
                    if(reason == 'reset' && value && corporates.length == 1){
                      setValue('company', corporates[0].name);
                      setValue('companynameFurigana', corporates[0].furigana);
                      setValue('postalcode', corporates[0].postCode);
                      setValue('state', corporates[0].prefectureName);
                      setValue('city', corporates[0].cityName);
                      setValue('street', corporates[0].streetNumber);
                      setValue('corporatenumberAccount', corporates[0].corporateNumber);
                    }
                  }}
                  renderInput={(params) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          {pform.label}
                        </InputLabel>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                          ※株式会社/有限会社等は入力しない
                        </InputLabel>
                        <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                          ※エンターキーでは検索できません
                        </InputLabel>
                      </Grid>
                      <Grid item md={9} sm={12} xs={12}>
                        <Element name={pform.fields[0].id}></Element>
                        <FormGroup row >
                          <TextFieldWText
                            {...field}
                            {...params}
                            ref={corpRef}
                            sx={{mt: {xs: 1, md: 2}}}
                            error={fieldState.invalid}
                            helperText={fieldState.error?.message}
                            margin="normal"
                          />
                          <TextFieldWButton sx={{mt: {xs: 1, md: 2}}} variant="contained" onClick={searchCompany}>検索</TextFieldWButton>
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}
                />
              : pform.fields[0].id == 'company' && kindState == '個人事業主' ?
                <Grid container>
                  <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                    <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                      {kindState == '個人事業主' && (pform.fields[0].id == 'company' || pform.fields[0].id == 'companynameFurigana') ? pform.label.replace('会社名', '屋号') : pform.label}
                    </InputLabel>
                    <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                      {kindState == '個人事業主' && (pform.fields[0].id == 'company' || pform.fields[0].id == 'companynameFurigana') ? '※屋号がない場合は代表者氏名を入力' : pform.fields[0].message}
                    </InputLabel>
                  </Grid>
                  <Grid item md={9} sm={12} xs={12}>
                    <Element name={pform.fields[0].id}></Element>
                    <TextField
                      {...field}
                      fullWidth
                      sx={{mt: {xs: 1, md: 2}}}
                      margin="normal"
                      color="secondary"
                      placeholder={pform.fields[0].placeholder}
                      disabled={
                        pform.fields[0].disable ?
                          pform.fields[0].disable :
                          false
                      }
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message}
                    />
                  </Grid>
                </Grid>
              :
                <Autocomplete
                  disablePortal
                  disabled={pform.fields[0].disable ? pform.fields[0].disable : false}
                  options={branches.map((option) => option.name)}
                  value={selectedBranch}
                  onChange={(_event, value) => {
                    if(value && branches.length > 0){
                      setValue('branchname', value);
                      setSelectedBranch(value);
                      const branch = branches.find((branch) => branch.name == value);
                      setValue('branchcode', branch ? branch.code : '');
                      setValue('branchnameHiragana', branch ? branch.hiragana : '');
                      setValue('branchnameFullwidthkana', branch ? branch.fullWidthKana : '');
                      setValue('branchnameHalfwidthkana', branch ? branch.halfWidthKana : '');
                    }
                  }}
                  onInputChange={(_event, value, reason) =>{
                    if(reason == 'clear'){
                      setValue('branchname', '');
                      setSelectedBranch('');
                      setValue('branchcode', '');
                      setValue('branchnameHiragana', '');
                      setValue('branchnameFullwidthkana', '');
                      setValue('branchnameHalfwidthkana', '');
                    }
                  }}
                  renderInput={(params) => (
                    <Grid container>
                      <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                        <InputLabel required={pform.required} sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                          {pform.label}
                        </InputLabel>
                      </Grid>
                      <Grid item md={9} sm={12} xs={12}>
                        <Element name={pform.fields[0].id}></Element>
                        <TextField
                          {...field}
                          {...params}
                          ref={branchRef}
                          margin="normal"
                          sx={{mt: {xs: 1, md: 2}}}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
            )}
          />
        : pform.fields.length == 1 && (pform.fields[0].type == 'file') ?
          <Controller
            control={control}
            rules={validationRules[pform.fields[0].id]}
            name={pform.fields[0].id}
            key={pform.fields[0].id}
            render={({ field: {onChange, value }, fieldState}) =>(
              <Grid container>
                <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                    {pform.label}
                  </InputLabel>
                  <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces', color: '#FF0000', fontSize: 10}}>
                    {pform.fields[0].message}
                  </InputLabel>
                </Grid>
                <Grid item md={9} sm={12} xs={12} sx={{pr: 2}}>
                  <Element name={pform.fields[0].id}></Element>
                  <FormControl margin='normal' fullWidth error={fieldState.invalid}>
                    <DragDropFileUpload onFileUpload={onChange} input_id={1} inputValue={value}/>
                    <FormHelperText>{fieldState.error?.message}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          />
        :
          <Box key={`pf_n_${idx}`}></Box>
      ))}
      </Box>
      </Box>
      ))}
      <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
        <Button
          variant='contained'
          color='warning'
          onClick={() => onClickCancel()}
          sx={{borderRadius: 5, width:  {md: '30%', xs: '45%'}, minWidth: '120px', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}, mr: 1}}
        >
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '45%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          登録する
        </Button>
      </Box>
      </form>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
      <WarningModal message={
        <>
          <Box>改めて本人確認が必要な項目（氏名、生年月日、メールアドレス）が変更されています。</Box>
          <Box>変更後は本人確認が完了するまでファクタリング申請できません。</Box>
          <Box>お急ぎの場合は先にファクタリング申請することで、審査結果を待ちながら本人確認できます。</Box>
          <Box>このまま登録しますか？</Box>
        </>
      } modalOpen={warningModalOpen} isCancel={isCancel} cancelClick={onClickWarningCancel} cancelButton="審査を申し込む" okClick={onClickWarningOk} okButton="登録する" handleClose={handleWarningModal}></WarningModal>
    </Box>
  );
}

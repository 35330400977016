import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSession, signIn } from "../../lib/api/auth";
import { AuthContextType, useAuthContext } from "../../providers/AuthUser";
import { Button, Container, Grid, InputLabel, TextField, Box, Link as MuiLink, Typography, InputAdornment, IconButton } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Loading from "../../components/Loading";
import { getProfileContact } from "../../lib/api/profile";
import { ErrorModal } from "../../components/ErrorModal";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const Signin = () =>{
  const { control, handleSubmit, setValue, getValues} = useForm({defaultValues: {email: '', password: ''}});
  const authUser:AuthContextType = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const email = query.get('email');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const navigate = useNavigate();

  const setToken = async () =>{
    const res = await getSession();
    const input = document.getElementsByName('authenticity_token');
    input[0].value = Cookies.get('CSRF-TOKEN');
  }

  useEffect(() =>{
    setToken();
    if(email){
      setValue('email', email);
    }
  }, []);

  const handleSignInSubmit = async (e) => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      e['currentUserId'] = Cookies.get('currentUserId');
      const res = await signIn(e);
      setIsLoading(false);
      if (res.status === 200) {
        const users = Cookies.get("_users") ? JSON.parse(Cookies.get("_users")) : [];
        Cookies.set("_access_token", res.headers["access-token"]);
        Cookies.set("_client", res.headers["client"]);
        Cookies.set("_uid", res.headers["uid"]);
        Cookies.set("_email", res.data.data.email);
        const contactRes = await getProfileContact();
        const headerName = `${contactRes.data.user.company}`;
        if(!users.find((u) => u._uid == res.headers['uid'])){
          users.push({
            _access_token: res.headers["access-token"],
            _client: res.headers["client"],
            _uid: res.headers["uid"],
            _email: res.data.data.email,
            _user_id: res.data.data.id,
            _is_sms_verified: res.data.data.isSmsVerified,
            _header_text: headerName,
            _sms_verified_at: Cookies.get('smsVerifiedAt'),
            _uid_om: encodeURIComponent(res.headers["uid"])
          });
        }else{
          const indx = users.findIndex((u) => u._uid == res.headers['uid']);
          users[indx]._access_token = res.headers["access-token"];
          users[indx]._client = res.headers["client"];
          users[indx]._uid = res.headers["uid"];
          users[indx]._email = res.data.data.email;
          users[indx]._user_id = res.data.data.id;
          users[indx]._is_sms_verified = res.data.data.isSmsVerified;
          users[indx]._header_text = headerName;
          users[indx]._sms_verified_at = Cookies.get('smsVerifiedAt');
          users[indx]._uid_om = encodeURIComponent(res.headers["uid"]);
        }
        Cookies.set("_users", JSON.stringify(users));
        Cookies.set("_contact", JSON.stringify(contactRes.data.record));
        Cookies.set("_header_text", headerName);
        authUser.signin(res.data.data, () =>{
          if(res.data.data.isSmsVerified){
            navigate("/");
          }else{
            navigate("/signup/info")
          }
        });
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      const messages = e.response.data.errors[0].split("\n");
      setErrorMessage(<>{messages.map(m => <Typography>{m}</Typography>)}</>);
      handleOpenErrorModal();
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const googleSubmit = () =>{
    setIsLoading(true);
    const form = document.getElementById('google_form');
    form.submit();
    setTimeout(setIsLoading, 2000, false);
  }

  return (
    <Container
      sx={{
        width: {md: '80%', xs: '95%'},
        pt: 5,
        pb: 5
      }}
      >
      <form id='sign_in_form' onSubmit={handleSubmit(handleSignInSubmit)}>
        <Controller
          control={control}
          name='email'
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                  ユーザーID
                </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
                <TextField
                  {...field}
                  fullWidth
                  sx={{mt: {xs: 0, md: 2}}}
                  margin="normal"
                  color="secondary"
                  placeholder="アカウント作成時のメールアドレス"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              </Grid>
            </Grid>
          )}
        />
        <Controller
          control={control}
          name='password'
          render={({ field, fieldState }) => (
            <Grid container>
              <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
              <InputLabel required sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                パスワード
              </InputLabel>
              </Grid>
              <Grid item md={9} sm={12} xs={12}>
            <TextField
              {...field}
              fullWidth
              sx={{mt: {xs: 0, md: 2}}}
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              color="secondary"
              placeholder="半角英数字8文字以上"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              InputProps={{endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPassword ? <VisibilityOff sx={{mr: {xs: 1, md: 0}}}/> : <Visibility sx={{mr: {xs: 1, md: 0}}}/>}
                  </IconButton>
                </InputAdornment>
              )}}
            />
            </Grid>
            </Grid>
          )}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
        >
          ログイン
        </Button>
      </form>
      <form id='google_form' method="post" action="/api/v1/auth/google_oauth2">
        <div>
        <input type="hidden" name="authenticity_token" value=''></input>
        <img src='/icons/google_logo_icon_169090.svg' alt="Icon" className="google-icon"/>
        <a className='saml-text' onClick={googleSubmit}>Googleでログイン</a>
        </div>
      </form>
      <Box sx={{mt: 1, textAlign: {xs: 'left', md: 'center'}, fontSize: {xs: '12px', md: '16px'}}}>
        <MuiLink
          component={Link}
          to={Cookies.get('isSignedIn') && Cookies.get('isSignedIn') == 'true' ? "/signin" : "/signup"}
          underline="hover"
        >新規アカウント作成</MuiLink>
        <Box></Box>
        <MuiLink
          component={Link}
          to="/login/password/resetting"
          underline="hover"
        >再登録・パスワード再設定はこちら</MuiLink>
        <Box></Box>
        <MuiLink
          component={Link}
          to="/login/forget_id"
          underline="hover"
        >ユーザーID（メールアドレス）を忘れた方はこちら</MuiLink>
      </Box>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Container>
  );
}

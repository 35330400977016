import React, { useEffect, useState} from "react";
import { TableSortLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, RadioGroup, Radio, Box, Grid, CardContent, Typography, Card, Link as MuiLink, Checkbox, CardActions, FormGroup, FormControlLabel } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import { statusColors} from "../common/opportunity";
import dayjs from "dayjs";
import { getCollections, updateCollectionDate } from "../lib/api/collection";
import { getFile } from "../lib/api/opportunity";
import Loading from "../components/Loading";
import { ErrorModal } from "../components/ErrorModal";


interface Column {
  id: 'readCheck' | 'detailButton' | 'contractDate' | 'status' | 'amount' | 'dueDate' | 'files';
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: number) => string;
  type?: string;
  sortable: boolean;
}

const columns: Column[] = [
  { id: 'readCheck', label: '確認', minWidth: 60, align: 'center', sortable: false},
  { id: 'detailButton', label: '', minWidth: 120, align: 'center', sortable: false},
  { id: 'dueDate', label: '売掛先からの入金予定日', minWidth: 170, align: 'center', sortable: true, type: 'date'},
  { id: 'amount', label: '回収予定額', minWidth: 170, align: 'center', sortable: true},
  { id: 'status', label: 'ステイタス', minWidth: 170, align: 'center', sortable: true},
  { id: 'contractDate', label: '契約日', minWidth: 170, align: 'center', sortable: true},
  // { id: 'files', label: '契約書', minWidth: 170, align: 'center', sortable: false}
]

const mobileColumns: Column[] = [
  { id: 'dueDate', label: '売掛先からの入金予定日', minWidth: 170, align: 'center', sortable: true},
  { id: 'amount', label: '回収予定額', minWidth: 170, align: 'center', sortable: true},
  { id: 'status', label: 'ステイタス', minWidth: 170, align: 'center', sortable: true},
  { id: 'contractDate', label: '契約日', minWidth: 170, align: 'center', sortable: true, type: 'date'},
  { id: 'detailButton', label: '', minWidth: 170, align: 'center', sortable: false},
  { id: 'files', label: '契約書', minWidth: 170, align: 'center', sortable: false}
]

interface File {
  name: string;
  id: string;
}

interface ReadCheck{
  id: string;
  disabled: boolean;
  checked: boolean;
}

interface Data {
  detailButton: string;
  contractDate: string;
  status: string;
  amount: number;
  dueDate: string;
  readCheck: ReadCheck;
  files: File[];
}

function createDate(
  detailButton: string,
  contractDate: string,
  status: string,
  amount: number,
  dueDate: string,
  files: File[],
  readCheck: ReadCheck
): Data{
  return {detailButton, contractDate, status, amount, dueDate, files, readCheck}
}

export const PaymentList = () =>{
  const [listData, setListData] = useState<Data[]>([]);
  const [checkData, setCheckData] = useState([]);
  const [selectedReadCheck, setSelectedReadCheck] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const type = query.get('type');
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();

  // ソート関連は以下
  // sort方法
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  //ソート対象
  const [orderBy, setOrderBy] = useState<string>("dueDate");

  //ソート対象、方法の変更
  const handleRequestSort = (event:any, property:any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property:any) => (event:any) => {
    handleRequestSort(event, property);
  };

  //ソート実行
  const stableSort = (array:any, comparator:any) => {
    const stabilizedThis = array.map((el:any, index:any) => [el, index]);
    stabilizedThis.sort((a:any, b:any) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el:any) => el[0]);
  }

  //比較処理
  const descendingComparator = (a:any, b:any, orderBy:any)=> {
    if (a[orderBy] == null) return 1;
    if (b[orderBy] == null ) return -1;
    if(orderBy == 'amount'){
      if(!b[orderBy] || !a[orderBy]){
        return 0;
      }
      if(Number(b[orderBy].replace(/,/g, '')) < Number(a[orderBy].replace(/,/g, ''))){
        return -1;
      }
      if(Number(b[orderBy].replace(/,/g, '')) > Number(a[orderBy].replace(/,/g, ''))){
        return 1;
      }
      return 0;
    }else{
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }
  const getComparator = (order:any, orderBy:any)=> {
    return order === "desc" 
      ? (a:any, b:any) => descendingComparator(a, b, orderBy)
      : (a:any, b:any) => -descendingComparator(a, b, orderBy);
  }

  const handleGetOpportunities = async () => {
    let res;
    if(!type){
      res = await getCollections(0);
    }else if(type == '3'){
      res = await getCollections(3);
    }else if(type == '7'){
      res = await getCollections(7);
    }

    if (res.status === 200) {
      const lists : Array<Data> = [];
      res.data.records.forEach(rec =>{
        let disabled = false;
        let checked = false;
        if(rec.threedayscheck == 1){
          checked = true;
          disabled = true;
        }else if(rec.sevendayscheck == 1){
          checked = true;
          disabled = true;
        }else if(rec.sevendayscheck == 0 && rec.threedayscheck == 0){
          disabled = true;
        }
        lists.push(createDate(
          rec.opportunityId,
          rec.aContractdateC  || '-',
          rec.portalStatusC == '入金予定日前' && rec.delayCheck ? '要確認' : rec.portalStatusC,
          rec.recoveredamountC ? rec.recoveredamountC.toLocaleString() : '-',
          rec.collectiondateC || '-',
          rec.files,
          {id: rec.id, disabled: disabled, checked: checked})
        );
      })
      setListData(lists);
    }
  }

  useEffect(() => {
    handleGetOpportunities()
  }, [])

  const handleFileClick = async (s3_id) =>{
    const res = await getFile(s3_id);
    if(res.status == 200){
      open(res.data.url, '_blank');
    }
  }

  const readOpp = async() =>{
    if(isLoading) return;
    setIsLoading(true);
    try{
      if(selectedReadCheck.length > 0){
        const ret = await updateCollectionDate(selectedReadCheck);
        if(ret.status == 200){
          const lists : Array<Data> = [];
          listData.forEach(ld =>{
            if(selectedReadCheck.indexOf(ld.readCheck.id) >= 0){
              lists.push(createDate(ld.detailButton, ld.contractDate || '-', ld.status, ld.amount, ld.dueDate || '-', ld.files, {id: ld.readCheck.id, disabled: true, checked: true}));
            }else{
              lists.push(createDate(ld.detailButton, ld.contractDate || '-', ld.status, ld.amount, ld.dueDate || '-', ld.files, ld.readCheck))
            }
          });
          setListData(lists);
          setIsLoading(false);
        }
      }else{
        setIsLoading(false);
        setErrorMessage(<Typography>確認する申請を選択してください</Typography>);
        handleOpenErrorModal();
      }
    }catch(e){
      setIsLoading(false);
      setErrorMessage(<Typography>{e.response.data.errors[0]}</Typography>);
      handleOpenErrorModal();
    }
  }

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleChange = (evt, targetId) =>{
    let newArray = [];
    if (evt.target.checked){
      newArray = selectedReadCheck.concat([targetId]);
    }else{
      newArray = selectedReadCheck.filter(n => n !== targetId);
    }
    setSelectedReadCheck(newArray);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', display: {xs: 'none', md: 'block'}}}>
      <TableContainer sx={{ maxHeight: '70vh' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            {columns.map((column) =>(
                column.id != 'detailButton' && column.id != 'readCheck' ?
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                    sortDirection={orderBy === column.id ? order : 'desc'}
                    onClick={createSortHandler(column.id)}
                    sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                  >
                    {column.label}
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                      sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                    ></TableSortLabel>
                  </TableCell>
                : <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth}}
                    sortDirection={orderBy === column.id ? order : 'desc'}
                    sx={{backgroundColor: "#FFF", p: 1, fontWeight: 'bold'}}
                  >
                    {column.label}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(listData, getComparator(order, orderBy))
              .map((row, index) =>{
                return (
                  <TableRow hover tabIndex={-1} key={row.detailButton + '_' + index}>
                    {columns.map((column, idx) =>{
                      const value = column.type == 'date' ? dayjs(row[column.id]).format('YYYY-MM-DD') : row[column.id];
                      const statusColor = column.id == 'status' ? statusColors[value] : "white";
                      return(
                        <TableCell key={column.id + '_' + idx} align={column.align} sx={{p: 1}}>
                          {column.id == 'readCheck' ?
                            <Checkbox defaultChecked={value.checked} disabled={value.disabled} onChange={(e) => handleChange(e, value.id)}/>
                            : column.id == 'detailButton' ?
                            <Button
                              variant='contained'
                              component={Link}
                              to={`/status/detail/${row.detailButton}`}
                              sx={{borderRadius: 5, fontWeight: 'bold'}}
                            >詳細を確認</Button>
                            : column.id == 'status'
                            ? <Typography sx={{borderRadius: 5, maxWidth: '120px', fontWeight: 'bold', border: 2, borderColor: statusColor, color: statusColor, backgroundColor: "#FFF", m: 'auto'}}
                            >
                              {value}
                            </Typography>
                            : column.id == 'files'
                            ? value.map((f, indx) =>{
                              return (
                                <Box key={'file_' + idx + '_' + indx}>
                                <MuiLink onClick={() => handleFileClick(f.id)}>
                                  {f.name}
                                </MuiLink>
                                </Box>
                              )
                            })
                            : column.format && typeof value === 'number'
                            ? column.format(value)
                            : value }
                        </TableCell>
                      )
                    })}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      <Box sx={{ width: '100%', display: {xs: 'block', md: 'none'}}}>
      <Grid
        key='mobile_grid'
        container
        direction="column"
        alignContent="center"
        justifyContent="center"
        sx={{flexWrap: 'nowrap', WebkitFlexWrap: 'nowrap'}}
      >
      {listData
        .map((row, idx) =>{
          const statusColor = row.status ? statusColors[row.status] : "white";
          return (<Grid item xs="auto" key={row.detailButton}>
          <Card
            variant="outlined"
            sx={{
              mt: 1,
              borderRadius: 3,
              pb: 1,
              mb: 2
            }}
          >
            <CardContent sx={{borderBottom: '1px solid #E7E6E0', pb: 1}}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={4} sx={{textAlign: 'right'}}>
                      <Typography>
                        確認
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{textAlign: 'left', pl: 2}}>
                      <Checkbox sx={{p: 0, maxHeight: '25px'}} defaultChecked={row.readCheck.checked} disabled={row.readCheck.disabled} onChange={(e) => handleChange(e, row.readCheck.id)}/>
                    </Grid>
                    <Grid item xs={4} sx={{textAlign: 'right'}}>
                      <Typography>
                        契約日
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{textAlign: 'left', pl: 2}}>
                      <Typography>
                        {row.contractDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sx={{m: 'auto 0'}}>
                  <Typography sx={{alignContent: 'right', borderRadius: 5, maxWidth: '120px', fontWeight: 'bold', border: 2, borderColor: statusColor, color: statusColor, backgroundColor: "#FFF", m: 'auto'}}
                    >
                    {row.status}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  売掛先からの入金予定日
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  <Typography>
                    {row.dueDate}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  回収予定額
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  <Typography>{row.amount}</Typography>
                </Grid>
              </Grid>
              {/* <Grid container sx={{mt: 1}}>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  fontWeight: 'bold',
                  pl: 3
                }}>
                  契約書
                </Grid>
                <Grid item xs={12} sx={{
                  m: 'auto',
                  textAlign: 'left',
                  pl: 5
                }}>
                  {row.files.map((f, indx) =>{
                    return (
                      <Box key={'file_' + idx + indx}>
                        <MuiLink onClick={() => handleFileClick(f.id)}>
                          {f.name}
                        </MuiLink>
                      </Box>
                    )
                  })}
                </Grid>
              </Grid> */}
            </CardContent>
            <CardActions sx={{display: 'block', pr: 1, pl: 1}}>
              <Button
                variant='contained' 
                component={Link}
                to={`/add_file/${row.detailButton}`}
                sx={{borderRadius: 5, width: '100%', fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
              >
                詳細を確認
              </Button>
            </CardActions>
          </Card>
        </Grid>
        );
        })
      }
      </Grid>
      </Box>
      <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
        <Button variant='contained' color='secondary' onClick={readOpp} sx={{borderRadius: 5, fontWeight: 'bold', width: {xs: '100%', md: '30%'}}}>既読にする</Button>
      </Box>
      {isLoading ? <Loading inverted={isLoading}/> : <></>}
      <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </Box>
  );
};